[ngbdropdown] {
  &.show {
    .dropdown-arrow {
      display: block;
    }
  }

  .dropdown-arrow {
    $border-width-inner: 0.5rem;
    $border-width: calc(#{$border-width-inner} + 1px);

    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 100%;
    min-width: 6rem;
    height: calc(#{$border-width} * 2);
    transform: translateX(-50%);
    z-index: 1010;

    &:after, &:before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 50%;
      height: 0;
      width: 0;
      border: solid transparent;
      border-color: transparent;
      transform: translateX(50%);
    }

    &:after {
      border-bottom-color: var(--bs-dark);
      border-width: $border-width-inner;
    }

    &:before {
      border-bottom-color: var(--bs-gray-700);
      border-width: $border-width;
    }

    +.dropdown-menu {
      margin-top: calc((#{$border-width} * 2) - 2.1px) !important;
    }
  }
}

