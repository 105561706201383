.form-field.magic-label {
  &:not(.has-label) {
    > label {
      background-color: transparent;

      app-form-disabled, app-form-error {
        background-color: #{$form-control-background-color};
        padding: 0 5px;
      }
    }
  }

  > label {
    position: absolute;
    top: calc(50% - #{$form-control-font-size} / 2);
    left: calc(#{$form-control-padding-x} - 5px);
    background-color: #{$form-control-background-color};
    height: auto;
    padding: 0 5px;
    margin: 0;
    line-height: 1;
    font-size: #{$form-control-font-size};
    user-select: none;
    cursor: text;
    transition: all 200ms ease;
  }

  > .form-icon {
    top: #{$form-icon-top-calc-without-label};
  }

  > .form-control {
    box-shadow: none;
  }

  &.has-icon {
    &:not(.focus):not(.filled) {
      > label {
        left: calc(#{$form-control-padding-x-with-icon} - 5px);
      }
    }
  }

  &.focus, &.filled {
    > label {
      top: calc(0rem - 0.75rem / 2);
      font-size: 0.75rem;
      cursor: auto;
    }
  }
}
