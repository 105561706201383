app-form-select {
  .form-icon {
    &.form-icon-toggle {
      left: unset !important;
      right: #{$form-control-padding-x};
    }
  }

  .form-control {
    padding-right: #{$form-control-padding-x-with-icon} !important;
    display: flex;
    align-items: center;

    .form-control-value {
      position: relative;
      top: 1px;
      line-height: 1;
    }
  }

  .dropdown {
    position: unset;
  }

  .dropdown-toggle {
    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    width: calc(100%);
    min-width: 6.5rem;
    top: 100% !important;
    transform: unset !important;
    max-height: 120px;
    overflow: unset !important;
    background-color: #{$form-control-background-color};
    border-color: #{$form-control-border-color};

    .dropdown-item {
      font-size: #{$form-control-font-size};
      width: 100%;
      outline: none;
      color: #{$form-control-color};

      &:hover {
        background-color: #{$form-dropdown-item-background-color-onhover};
      }

      &:active, &.selected {
        background-color: #{$form-active-color};
        color: #{$form-active-color-contrast};
      }
    }
  }

}
