@font-face {
  font-family: "teatrix-icons";
  src:
    url("/assets/fonts/teatrix-icons/teatrix-icons.woff2?t=1659393539789") format("woff2"),
    url("/assets/fonts/teatrix-icons/teatrix-icons.woff?t=1659393539789") format("woff"),
    url("/assets/fonts/teatrix-icons/teatrix-icons.ttf?t=1659393539789") format("truetype"), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
    url("/assets/fonts/teatrix-icons/teatrix-icons.svg?t=1659393539789#teatrix-icons") format("svg"); /* iOS 4.1- */
}


[class^="teatrix-icon-"], [class*=" teatrix-icon-"] {
  font-family: "teatrix-icons" !important;
  font-size: 1rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.teatrix-icon-angle-down:before { content: "\ea01"; }
.teatrix-icon-applause:before { content: "\ea02"; }
.teatrix-icon-back:before { content: "\ea03"; }
.teatrix-icon-cake:before { content: "\ea04"; }
.teatrix-icon-caret-right:before { content: "\ea05"; }
.teatrix-icon-catalog:before { content: "\ea06"; }
.teatrix-icon-cc:before { content: "\ea07"; }
.teatrix-icon-chats:before { content: "\ea08"; }
.teatrix-icon-check-circle:before { content: "\ea09"; }
.teatrix-icon-check:before { content: "\ea0a"; }
.teatrix-icon-claps-filled:before { content: "\ea0b"; }
.teatrix-icon-claps:before { content: "\ea0c"; }
.teatrix-icon-close-cicle:before { content: "\ea0d"; }
.teatrix-icon-close:before { content: "\ea0e"; }
.teatrix-icon-confetti:before { content: "\ea0f"; }
.teatrix-icon-contact:before { content: "\ea10"; }
.teatrix-icon-credit-card:before { content: "\ea11"; }
.teatrix-icon-curtain:before { content: "\ea12"; }
.teatrix-icon-diamond:before { content: "\ea13"; }
.teatrix-icon-double-arrow-bottom:before { content: "\ea14"; }
.teatrix-icon-email:before { content: "\ea15"; }
.teatrix-icon-flag-argentina:before { content: "\ea16"; }
.teatrix-icon-flag-brasil:before { content: "\ea17"; }
.teatrix-icon-flag-ecuador:before { content: "\ea18"; }
.teatrix-icon-flag-espana:before { content: "\ea19"; }
.teatrix-icon-flag-estados-unidos:before { content: "\ea1a"; }
.teatrix-icon-flag-inglaterra:before { content: "\ea1b"; }
.teatrix-icon-flag-uruguay:before { content: "\ea1c"; }
.teatrix-icon-flag:before { content: "\ea1d"; }
.teatrix-icon-fullhd:before { content: "\ea1e"; }
.teatrix-icon-gift:before { content: "\ea1f"; }
.teatrix-icon-info-circle:before { content: "\ea20"; }
.teatrix-icon-jwplayer-forward-10:before { content: "\ea21"; }
.teatrix-icon-jwplayer-rewind-10:before { content: "\ea22"; }
.teatrix-icon-jwplayer-rewind-full-alt:before { content: "\ea23"; }
.teatrix-icon-jwplayer-rewind-full:before { content: "\ea24"; }
.teatrix-icon-logout:before { content: "\ea25"; }
.teatrix-icon-menu-item-catalog:before { content: "\ea26"; }
.teatrix-icon-menu-item-collections:before { content: "\ea27"; }
.teatrix-icon-menu-item-home:before { content: "\ea28"; }
.teatrix-icon-menu-item-more:before { content: "\ea29"; }
.teatrix-icon-menu-item-search:before { content: "\ea2a"; }
.teatrix-icon-menu-toggle:before { content: "\ea2b"; }
.teatrix-icon-news:before { content: "\ea2c"; }
.teatrix-icon-notification:before { content: "\ea2d"; }
.teatrix-icon-partners:before { content: "\ea2e"; }
.teatrix-icon-phone:before { content: "\ea2f"; }
.teatrix-icon-pin:before { content: "\ea30"; }
.teatrix-icon-plus-circle:before { content: "\ea31"; }
.teatrix-icon-ppv-play:before { content: "\ea32"; }
.teatrix-icon-quote-left:before { content: "\ea33"; }
.teatrix-icon-quote-right:before { content: "\ea34"; }
.teatrix-icon-recovered-play:before { content: "\ea35"; }
.teatrix-icon-save:before { content: "\ea36"; }
.teatrix-icon-search:before { content: "\ea37"; }
.teatrix-icon-share:before { content: "\ea38"; }
.teatrix-icon-star:before { content: "\ea39"; }
.teatrix-icon-start-filled:before { content: "\ea3a"; }
.teatrix-icon-subscribe:before { content: "\ea3b"; }
.teatrix-icon-teatrix:before { content: "\ea3c"; }
.teatrix-icon-theater:before { content: "\ea3d"; }
.teatrix-icon-ticket:before { content: "\ea3e"; }
.teatrix-icon-trash:before { content: "\ea3f"; }
.teatrix-icon-user-avatar:before { content: "\ea40"; }
.teatrix-icon-warning:before { content: "\ea41"; }
.teatrix-icon-whatsapp:before { content: "\ea42"; }

$teatrix-icon-angle-down: "\ea01";
$teatrix-icon-applause: "\ea02";
$teatrix-icon-back: "\ea03";
$teatrix-icon-cake: "\ea04";
$teatrix-icon-caret-right: "\ea05";
$teatrix-icon-catalog: "\ea06";
$teatrix-icon-cc: "\ea07";
$teatrix-icon-chats: "\ea08";
$teatrix-icon-check-circle: "\ea09";
$teatrix-icon-check: "\ea0a";
$teatrix-icon-claps-filled: "\ea0b";
$teatrix-icon-claps: "\ea0c";
$teatrix-icon-close-cicle: "\ea0d";
$teatrix-icon-close: "\ea0e";
$teatrix-icon-confetti: "\ea0f";
$teatrix-icon-contact: "\ea10";
$teatrix-icon-credit-card: "\ea11";
$teatrix-icon-curtain: "\ea12";
$teatrix-icon-diamond: "\ea13";
$teatrix-icon-double-arrow-bottom: "\ea14";
$teatrix-icon-email: "\ea15";
$teatrix-icon-flag-argentina: "\ea16";
$teatrix-icon-flag-brasil: "\ea17";
$teatrix-icon-flag-ecuador: "\ea18";
$teatrix-icon-flag-espana: "\ea19";
$teatrix-icon-flag-estados-unidos: "\ea1a";
$teatrix-icon-flag-inglaterra: "\ea1b";
$teatrix-icon-flag-uruguay: "\ea1c";
$teatrix-icon-flag: "\ea1d";
$teatrix-icon-fullhd: "\ea1e";
$teatrix-icon-gift: "\ea1f";
$teatrix-icon-info-circle: "\ea20";
$teatrix-icon-jwplayer-forward-10: "\ea21";
$teatrix-icon-jwplayer-rewind-10: "\ea22";
$teatrix-icon-jwplayer-rewind-full-alt: "\ea23";
$teatrix-icon-jwplayer-rewind-full: "\ea24";
$teatrix-icon-logout: "\ea25";
$teatrix-icon-menu-item-catalog: "\ea26";
$teatrix-icon-menu-item-collections: "\ea27";
$teatrix-icon-menu-item-home: "\ea28";
$teatrix-icon-menu-item-more: "\ea29";
$teatrix-icon-menu-item-search: "\ea2a";
$teatrix-icon-menu-toggle: "\ea2b";
$teatrix-icon-news: "\ea2c";
$teatrix-icon-notification: "\ea2d";
$teatrix-icon-partners: "\ea2e";
$teatrix-icon-phone: "\ea2f";
$teatrix-icon-pin: "\ea30";
$teatrix-icon-plus-circle: "\ea31";
$teatrix-icon-ppv-play: "\ea32";
$teatrix-icon-quote-left: "\ea33";
$teatrix-icon-quote-right: "\ea34";
$teatrix-icon-recovered-play: "\ea35";
$teatrix-icon-save: "\ea36";
$teatrix-icon-search: "\ea37";
$teatrix-icon-share: "\ea38";
$teatrix-icon-star: "\ea39";
$teatrix-icon-start-filled: "\ea3a";
$teatrix-icon-subscribe: "\ea3b";
$teatrix-icon-teatrix: "\ea3c";
$teatrix-icon-theater: "\ea3d";
$teatrix-icon-ticket: "\ea3e";
$teatrix-icon-trash: "\ea3f";
$teatrix-icon-user-avatar: "\ea40";
$teatrix-icon-warning: "\ea41";
$teatrix-icon-whatsapp: "\ea42";

