$form-active-color: var(--app-form-active-color, var(--bs-gray-500));
$form-active-color-contrast: var(--app-form-active-color-contrast, var(--bs-white));
/* -------------------- */
$form-label-margin-bottom: 0.5rem;
$form-label-line-height: 1;
$form-label-color: var(--app-form-label-color, var(--bs-gray-600));
$form-label-font-size: var(--app-form-label-font-size, 0.9rem);
/* -------------------- */
$form-control-color: var(--app-form-control-color, var(--bs-dark));
$form-control-font-family: var(--app-form-control-font-family, var(--app-ff-gilroy-sb));
$form-control-font-size: var(--app-form-control-font-size, 0.9rem);
$form-control-background-color: var(--app-form-control-background-color, var(--bs-white));
$form-control-border-color: var(--app-form-control-border-color, var(--bs-gray-400));
$form-control-border-color-onfocus: var(--app-form-control-border-color-onfocus, var(--bs-gray-700));
$form-control-height: var(--app-form-control-height, 2.5rem);
$form-control-padding-y: 0.375rem;
$form-control-padding-x: 0.75rem;
$form-control-padding-x-with-icon: calc(#{$form-control-padding-x} + 2rem);
/* -------------------- */
$form-icon-font-size: var(--app-form-icon-font-size, 1rem);
$form-icon-top-calc: calc(#{$form-label-font-size} + #{$form-label-margin-bottom} + 5px + (#{$form-control-height} / 2));
$form-icon-top-calc-without-label: calc(#{$form-control-height} / 2);
$form-icon-top-calc-one-line: calc(#{$form-label-font-size} + #{$form-label-margin-bottom} + (#{$form-control-height} / 2));
/* -------------------- */
$form-dropdown-item-background-color-onhover: var(--app-form-dropdown-item-background-color-onhover, rgba(0,0,0,0.1));

.form__ondark {
  --app-form-label-color: var(--bs-gray-500);
  /* -------------------- */
  --app-form-control-color: var(--bs-white);
  --app-form-control-background-color: var(--bs-dark);
  --app-form-control-border-color: var(--bs-gray-600);
  --app-form-control-border-color-onfocus: var(--bs-gray-300);
  /* -------------------- */
  --app-form-dropdown-item-background-color-onhover: rgba(255,255,255,0.1);

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

@import './form-field.scss';
@import './form-field-one-line.scss';
@import './form-field-magic-label.scss';

@import '../components/form-fields/form-input/form-input.component.scss';
@import '../components/form-fields/form-textarea/form-textarea.component.scss';
@import '../components/form-fields/form-select/form-select.component.scss';
@import '../components/form-fields/form-checkbox/form-checkbox.component.scss';
@import '../components/form-fields/form-dropzone/form-dropzone.component.scss';
