.form__ondark app-form-checkbox {
  .form-check {
    &.form-switch {
      .form-check-input {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");

        &:focus {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
        }
      }
    }
  }
}

app-form-checkbox {
  display: block;

  .form-check {
    &:not(.form-switch) {
      [type="checkbox"] {
        border-radius: 0;
      }
    }

    &.form-switch {
      .form-check-input {
        &:focus {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
        }

        &:checked {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
        }
      }
    }

    .form-check-input {
      background-color: $form-control-background-color;
      border-color: $form-control-border-color;
      box-shadow: none !important;

      &:checked {
        background-color: $form-active-color;
        border-color: $form-active-color;
      }

      &:focus {
        border-color: $form-active-color;
      }
    }

    .form-check-label {
      color: $form-control-color;
      margin-bottom: 0;
      width: 100%;
    }
  }

  .is-disabled {
    .form-check-input {
      &:checked {
        opacity: 0.5;
      }
    }

    .form-check-label {
      app-form-disabled {
        display: inline-block;
      }

      color: $form-label-color;
    }
  }
}
