@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

.title-line-bottom-red,
.title-line-bottom-dark {
  &:after {
    content: '';
    display: block;
    width: 4rem;
    height: 4px;
    background: var(--bs-app-red);
    margin-top: 1rem;
  }
}

.title-line-bottom-dark:after {
  background: var(--bs-dark);

  @include media-breakpoint-down(md) {
    display: none;
  }
}
