app-form-dropzone-upload {
  .form-field {
    &.magic-label {
      .form-control {
        height: 9.5rem !important;
      }

      .form-field-actions {
        top: 0.5rem;
      }
    }

    &.one-line {
      .form-control {
        height: 12.5rem !important;
      }
    }

    &.magic-label.one-line {
      .form-control {
        height: 11.3rem !important;
      }
    }

    .form-control {
      height: 13.4rem !important;

      &.dragging {
        border-color: var(--bs-primary) !important;

        .form-control-placeholder-content {
          color: var(--bs-primary) !important;
        }
      }

      .form-control-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;

        .form-control-placeholder-content {
          width: 100%;
          color: #{$form-label-color};
          text-align: center;
          line-height: 1;
          transition: color 300ms ease;

          .progress {
            height: 0.5rem;
            margin-top: 0.25rem;
          }
        }
      }
    }

    .form-field-actions {
      position: absolute;
      top: 2.5rem;
      right: 0.5rem;
    }
  }
}

