/* You can add global styles to this file, and also import other style files */
@import "theme/bootstrap.scss";
// @import '../../node_modules/bootstrap/scss/bootstrap';

// Configuration
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// Responsive font sizes
$utilities: map-merge(
        $utilities,
        (
            "font-size": map-merge(
                    map-get($utilities, "font-size"),
                    (
                        responsive: true,
                        values: $font-sizes
                    )
            )
        )
);

// Layout & components
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
// @import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
// @import "../node_modules/bootstrap/scss/breadcrumb";
// @import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
// @import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
// @import "../node_modules/bootstrap/scss/offcanvas";

// Helpers
@import "../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";



@import "@fortawesome/fontawesome-free/css/all.min.css";
// @import "@fortawesome/fontawesome-free/css/brands.min.css";
@import "simplebar/dist/simplebar.min.css";
@import 'swiper/swiper-bundle';
@import 'app/packages/forms/theme/styles.scss';

@import "theme/vars.scss";
@import "theme/fonts/fonts.scss";
@import "theme/forms.scss";
@import "theme/buttons.scss";
@import "theme/texts.scss";
@import "theme/titles.scss";
@import "theme/grids.scss";
@import "theme/swipers.scss";
@import "theme/popovers.scss";
@import "theme/dropdowns.scss";
@import "theme/modals.scss";
@import "theme/swals.scss";
@import "theme/helpers.scss";

// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins/breakpoints";

html {
  font-size: 16px;
}

body {
  $padding-right-on-item-open: 17px;

  background: var(--bs-dark);
  color: var(--bs-white);

  &.modal-open {
    overflow: hidden !important;
    padding-right: 0 !important;

    // @include media-breakpoint-up(md) {
    //   padding-right: $padding-right-on-item-open;

    //   .fixed-top {
    //     padding-right: $padding-right-on-item-open;
    //   }
    // }
  }

  &.sidebar-open {
    overflow: hidden !important;
    padding-right: 0 !important;

    // @include media-breakpoint-up(md) {
    //   padding-right: $padding-right-on-item-open;
    // }
  }

  &.ready {
    #preloader {
      display: none !important;
    }

    app-subscribe-bar {
      opacity: 1;
    }
  }
}

.has-fixed-header {
  padding-top: var(--app-header-height);
}

.has-fixed-header-sm {
  @include media-breakpoint-up(sm) {
    padding-top: var(--app-header-height);
  }
}

.has-fixed-header-md {
  @include media-breakpoint-up(md) {
    padding-top: var(--app-header-height);
  }
}

.page-content {
  position: relative;
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  z-index: 10;
}

.page-content-top {
  position: relative;
  padding-top: 6.25rem;
  z-index: 10;
}

.page-content-bottom {
  position: relative;
  padding-bottom: 6.25rem;
  z-index: 10;
}

.section-content {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  z-index: 10;
}

.section-content-top {
  position: relative;
  padding-top: 5rem;
  z-index: 10;
}

.section-content-bottom {
  position: relative;
  padding-bottom: 5rem;
  z-index: 10;
}

@include media-breakpoint-down(sm) {
  .page-content, .page-content-top, .section-content, .section-content-top {
    padding-top: 2.25rem;
  }
}

/* -------------------- */

.d-inline-block {
  vertical-align: top;
}

.dropdown-toggle::after {
  content: none;
}

.container-fluid {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @include media-breakpoint-up(xl) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .container {
    padding: 0 !important;
  }
}

.container {
  @include media-breakpoint-down(sm) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1140px;
  }
}

/* -------------------- */

.intersection-observer-target {
  position: absolute;
  transform: translateY(-150px);
}
