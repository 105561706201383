app-form-dropzone-value {
  .form-field {
    &.magic-label {
      .form-control {
        .form-control-value {
          height: 6.95rem;
        }
      }
    }

    &.one-line {
      .form-control {
        .form-control-actions {
          padding: 0;
        }

        .form-control-value {
          height: 10.05rem;
        }
      }
    }

    &.magic-label.one-line {
      .form-control {
        .form-control-actions {
          height: #{$form-control-height};
        }

        .form-control-value {
          height: 8.75rem;
        }
      }
    }

    .form-control {
      height: auto !important;
      padding: 0;

      .form-control-actions {
        display: flex;
        align-items: center;
        height: calc(#{$form-control-height} - 1px);
        padding: #{$form-control-padding-y} #{$form-control-padding-x};
        border-bottom: 1px solid var(--bs-gray-500);
      }

      .form-control-value {
        height: 10.85rem;
        padding: #{$form-control-padding-y};

        .form-control-value-multimedia {
          object-fit: contain;
          width: 100%;
          height: 100%;
          outline: none;
        }

        .form-control-value-default {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          text-align: center;
          word-break: break-all;
        }
      }
    }
  }
}

