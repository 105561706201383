@import "../../node_modules/bootstrap/scss/functions";

$enable-smooth-scroll: false;

$font-family-base: var(--app-ff-gilroy-d);
$border-radius-lg: .5rem;

$grid-columns: 60;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  xxxxl: 1800px,
  xxxxxl: 2000px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1520px,
  xxxxl: 1720px,
  xxxxxl: 1920px
);

$aspect-ratios: (
  "1x1": 100%,
  "2x1": calc(1 / 2 * 100%),
  "3x1": calc(1 / 3 * 100%),
  "4x3": calc(3 / 4 * 100%),
  "3x4": calc(4 / 3 * 100%),
  "9x16": calc(16 / 9 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "16x10": calc(10 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);

/* -------------------- */

@import "../../node_modules/bootstrap/scss/variables";

$custom-theme-colors: (
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  /* -------------------- */
  "dark": #141414,
  "dark-1-": #2B2B2B,
  "dark-2-": #2A2A2A,
  "dark-3-": #3a3a3a,
  "white": #fff,
  /* -------------------- */
  "teatrix": #ec0024,
  "app-red": #ec0024,
  "app-red-1": #D30120,
  "app-red-3": #530b14,
  "app-red-4": #C11320,
  "app-green": #4AAD68,
  "app-green-1": #009A0F,
  "app-yellow": #FBFFC9,
  "app-yellow-1": #FCCA3E,
  "app-yellow-2": #FF8B02,
  /* -------------------- */
  "app-facebook": #3b5998,
  "app-instagram": #dd2a7b,
  "app-twitter": #00aaee,
  "app-youtube": #f70000,
  "app-linkedin": #0077b0,
  "app-whatsapp": #25d366,
  "app-google": #d04b32,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
// $utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
// $utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
// $utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
