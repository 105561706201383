label {
  display: inline-block;
  margin-bottom: $form-label-margin-bottom;
  line-height: $form-label-line-height;
  font-size: $form-label-font-size;
  color: $form-label-color;

  app-form-disabled {
    display: none;
  }

  app-form-error {
    display: none;
    color: var(--bs-danger) !important;

    ngb-popover-window {
      background-color: #{$form-control-background-color} !important;
      border-color: #{$form-control-border-color} !important;
      margin-top: 0.5rem;

      .popover-body {
        font-size: 0.75rem;
        padding: 0.5rem;
      }
    }
  }
}

.form-field {
  position: relative;

  > .form-icon {
    position: absolute;
    top: #{$form-icon-top-calc};
    left: #{$form-control-padding-x};
    font-size: 1rem;
    color: #{$form-control-color};
    transform: translateY(-50%);
  }

  > .form-control {
    background-image: none !important;
    background-color: #{$form-control-background-color};
    color: #{$form-control-color};
    height: #{$form-control-height};
    padding: #{$form-control-padding-y} #{$form-control-padding-x};
    font-size: #{$form-control-font-size};
    font-family: #{$form-control-font-family};
    line-height: 1;
    box-shadow: none !important;
    border-color: #{$form-control-border-color};
  }

  &:not(.has-label) {
    > label {
      display: none;
    }

    > .form-icon {
      top: #{$form-icon-top-calc-without-label};
    }
  }

  &.has-icon {
    > .form-control {
      padding-left: #{$form-control-padding-x-with-icon};
    }
  }

  &.focus {
    &:not(.is-invalid) {
      > .form-control {
        border-color: #{$form-control-border-color-onfocus};
      }
    }
  }

  &.is-disabled {
    > label {
      z-index: 1;

      app-form-disabled {
        display: inline-block;
      }
    }

    > .form-icon, > .form-control, > .form-field-actions {
      opacity: 0.25;
    }
  }

  // &.is-invalid {
  //   > label {
  //     color: var(--bs-danger) !important;
  //   }
  // }
}

.is-invalid {
  > label {
    app-form-error {
      display: inline-block;
    }
  }
}
