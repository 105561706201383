// @font-face {
//   font-family: "OpenSans-Light";
//   src: url("/assets/fonts/opensans/OpenSans-Light.woff2") format('woff2'),
//     url("/assets/fonts/opensans/OpenSans-Light.woff") format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "OpenSans-Regular";
//   src: url("/assets/fonts/opensans/OpenSans-Regular.woff2") format('woff2'),
//     url("/assets/fonts/opensans/OpenSans-Regular.woff") format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "OpenSans-SemiBold";
//   src: url("/assets/fonts/opensans/OpenSans-SemiBold.woff2") format('woff2'),
//     url("/assets/fonts/opensans/OpenSans-SemiBold.woff") format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "OpenSans-Bold";
//   src: url("/assets/fonts/opensans/OpenSans-Bold.woff2") format('woff2'),
//     url("/assets/fonts/opensans/OpenSans-Bold.woff") format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

:root {
  --app-ff-opensans-ul: "Open Sans";
  --app-ff-opensans-l: "Open Sans";
  --app-ff-opensans-r: "Open Sans";
  --app-ff-opensans-sb: "Open Sans";
  --app-ff-opensans-b: "Open Sans";
  /* -------------------- */
  --app-ff-opensans-d: var(--app-ff-opensans-r);
}

.ff-opensans-ul { font-family: var(--app-ff-opensans-ul) !important; font-weight: 300 !important; }
.ff-opensans-l { font-family: var(--app-ff-opensans-l) !important; font-weight: 300 !important; }
.ff-opensans-r { font-family: var(--app-ff-opensans-r) !important; font-weight: 400 !important; }
.ff-opensans-sb { font-family: var(--app-ff-opensans-sb) !important; font-weight: 700 !important; }
.ff-opensans-b { font-family: var(--app-ff-opensans-b) !important; font-weight: 700 !important; }
/* -------------------- */
.ff-opensans-d { font-family: var(--app-ff-opensans-r) !important; font-weight: 400 !important; }
