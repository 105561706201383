$pr: '_';

.#{$pr}h-100 { height: 100% !important; }
.#{$pr}mh-100 { min-height: 100% !important; }
.#{$pr}w-100 { width: 100% !important; }
.#{$pr}mw-100 { min-width: 100% !important; }

.#{$pr}va-m { vertical-align: middle !important; }

.#{$pr}border-merge-top { top: -1px !important; }
.#{$pr}border-merge-bottom { top: 1px !important; }

.#{$pr}img-contain-h { object-fit: contain !important; height: 100% !important; }
.#{$pr}img-contain-w { object-fit: contain !important; width: 100% !important; }
.#{$pr}img-contain-wh { object-fit: contain !important; width: 100% !important; height: 100% !important; }
.#{$pr}img-cover-h { object-fit: cover !important; height: 100% !important; }
.#{$pr}img-cover-w { object-fit: cover !important; width: 100% !important; }
.#{$pr}img-cover-wh { object-fit: cover !important; width: 100% !important; height: 100% !important; }

.#{$pr}bg-cover { background-size: cover; background-position: center; background-repeat: no-repeat; }
.#{$pr}bg-contain { background-size: contain; background-position: center; background-repeat: no-repeat; }

@for $i from 0 through 10 {
  .#{$pr}w-#{$i} { width: #{$i}rem !important; }
  .#{$pr}mw-#{$i} { min-width: #{$i}rem !important; }
  .#{$pr}h-#{$i} { height: #{$i}rem !important; }
  .#{$pr}mh-#{$i} { min-height: #{$i}rem !important; }
  .#{$pr}wh-#{$i} { width: #{$i}rem !important; height: #{$i}rem !important; }

  .#{$pr}f-#{$i} { font-size: #{$i}rem !important; }
  .#{$pr}lh-#{$i} { line-height: #{$i} !important; }

  .#{$pr}pot-#{$i} { position: relative; top: #{$i}rem !important; }
  .#{$pr}pot-#{$i}- { position: relative; top: -#{$i}rem !important; }
  .#{$pr}pob-#{$i} { position: relative; bottom: #{$i}rem !important; }
  .#{$pr}pob-#{$i}- { position: relative; bottom: -#{$i}rem !important; }

  .#{$pr}m-#{$i} { margin: #{$i} !important; }
  .#{$pr}ml-#{$i} { margin-left: #{$i}rem !important; }
  .#{$pr}mr-#{$i} { margin-right: #{$i}rem !important; }
  .#{$pr}mt-#{$i} { margin-top: #{$i}rem !important; }
  .#{$pr}mb-#{$i} { margin-bottom: #{$i}rem !important; }
  .#{$pr}my-#{$i} { margin-top: #{$i}rem !important; margin-bottom: #{$i}rem !important; }
  .#{$pr}mx-#{$i} { margin-left: #{$i}rem !important; margin-right: #{$i}rem !important; }

  .#{$pr}p-#{$i} { padding: #{$i}rem !important; }
  .#{$pr}pl-#{$i} { padding-left: #{$i}rem !important; }
  .#{$pr}pr-#{$i} { padding-right: #{$i}rem !important; }
  .#{$pr}pt-#{$i} { padding-top: #{$i}rem !important; }
  .#{$pr}pb-#{$i} { padding-bottom: #{$i}rem !important; }
  .#{$pr}py-#{$i} { padding-top: #{$i}rem !important; padding-bottom: #{$i}rem !important; }
  .#{$pr}px-#{$i} { padding-left: #{$i}rem !important; padding-right: #{$i}rem !important; }

  @if $i < 10 {
    @for $j from 0 through 9 {
      .#{$pr}w-#{$i}-#{$j} { width: #{$i + '.' + $j}rem !important; }
      .#{$pr}h-#{$i}-#{$j} { height: #{$i + '.' + $j}rem !important; }
      .#{$pr}wh-#{$i}-#{$j} { width: #{$i + '.' + $j}rem !important; height: #{$i + '.' + $j}rem !important; }

      .#{$pr}f-#{$i}-#{$j} { font-size: #{$i + '.' + $j}rem !important; }
      .#{$pr}lh-#{$i}-#{$j} { line-height: #{$i + '.' + $j} !important; }

      .#{$pr}pot-#{$i}-#{$j} { position: relative; top: #{$i + '.' + $j}rem !important; }
      .#{$pr}pot-#{$i}-#{$j}- { position: relative; top: -#{$i + '.' + $j}rem !important; }
      .#{$pr}pob-#{$i}-#{$j} { position: relative; bottom: #{$i + '.' + $j}rem !important; }
      .#{$pr}pob-#{$i}-#{$j}- { position: relative; bottom: -#{$i + '.' + $j}rem !important; }

      .#{$pr}m-#{$i}-#{$j} { margin: #{$i + '.' + $j}rem !important; }
      .#{$pr}ml-#{$i}-#{$j} { margin-left: #{$i + '.' + $j}rem !important; }
      .#{$pr}mr-#{$i}-#{$j} { margin-right: #{$i + '.' + $j}rem !important; }
      .#{$pr}mt-#{$i}-#{$j} { margin-top: #{$i + '.' + $j}rem !important; }
      .#{$pr}mb-#{$i}-#{$j} { margin-bottom: #{$i + '.' + $j}rem !important; }
      .#{$pr}my-#{$i}-#{$j} { margin-top: #{$i + '.' + $j}rem !important; margin-bottom: #{$i + '.' + $j}rem !important; }
      .#{$pr}mx-#{$i}-#{$j} { margin-left: #{$i + '.' + $j}rem !important; margin-right: #{$i + '.' + $j}rem !important; }

      .#{$pr}p-#{$i}-#{$j} { padding: #{$i + '.' + $j}rem !important; }
      .#{$pr}pl-#{$i}-#{$j} { padding-left: #{$i + '.' + $j}rem !important; }
      .#{$pr}pr-#{$i}-#{$j} { padding-right: #{$i + '.' + $j}rem !important; }
      .#{$pr}pt-#{$i}-#{$j} { padding-top: #{$i + '.' + $j}rem !important; }
      .#{$pr}pb-#{$i}-#{$j} { padding-bottom: #{$i + '.' + $j}rem !important; }
      .#{$pr}py-#{$i}-#{$j} { padding-top: #{$i + '.' + $j}rem !important; padding-bottom: #{$i + '.' + $j}rem !important; }
      .#{$pr}px-#{$i}-#{$j} { padding-left: #{$i + '.' + $j}rem !important; padding-right: #{$i + '.' + $j}rem !important; }
    }
  }
}

.#{$pr}op-0 { opacity: 0 !important; }
.#{$pr}op-1 { opacity: 1 !important; }

@for $i from 1 through 9 {
  .#{$pr}op-0-#{$i} { opacity: #{'0.' + $i} !important; }
}

@for $i from 1 through 10 {
  .#{$pr}zindex-#{$i} { z-index: #{$i} !important; }
}

:root {
  --fs-8: 0.5rem;
  --fs-10: 0.625rem;
  --fs-12: 0.75rem;
  --fs-13: 0.813rem;
  --fs-14: 0.875rem;
  --fs-15: 0.938rem;
  --fs-16: 1rem;
  --fs-18: 1.125rem;
  --fs-20: 1.25rem;
  --fs-22: 1.375rem;
  --fs-24: 1.5rem;
  --fs-25: 1.563rem;
  --fs-26: 1.625rem;
  --fs-28: 1.75rem;
  --fs-30: 1.875rem;
  --fs-32: 2rem;
  --fs-34: 2.125rem;
  --fs-36: 2.25rem;
  --fs-40: 2.5rem;
  --fs-44: 2.75rem;
  --fs-45: 2.813rem;
  --fs-48: 3rem;
  --fs-50: 3.125rem;
  --fs-56: 3.5rem;
  --fs-64: 4rem;
  --fs-72: 4.5rem;
  --fs-78: 4.875rem;
  --fs-80: 5rem;
  --fs-90: 5.625rem;
  --fs-100: 6.25rem;
}

.#{$pr}fs-8 { font-size: var(--fs-8) !important; }
.#{$pr}fs-10 { font-size: var(--fs-10) !important; }
.#{$pr}fs-12 { font-size: var(--fs-12) !important; }
.#{$pr}fs-13 { font-size: var(--fs-13) !important; }
.#{$pr}fs-14 { font-size: var(--fs-14) !important; }
.#{$pr}fs-15 { font-size: var(--fs-15) !important; }
.#{$pr}fs-16 { font-size: var(--fs-16) !important; }
.#{$pr}fs-18 { font-size: var(--fs-18) !important; }
.#{$pr}fs-20 { font-size: var(--fs-20) !important; }
.#{$pr}fs-22 { font-size: var(--fs-22) !important; }
.#{$pr}fs-24 { font-size: var(--fs-24) !important; }
.#{$pr}fs-25 { font-size: var(--fs-25) !important; }
.#{$pr}fs-26 { font-size: var(--fs-26) !important; }
.#{$pr}fs-28 { font-size: var(--fs-28) !important; }
.#{$pr}fs-30 { font-size: var(--fs-30) !important; }
.#{$pr}fs-32 { font-size: var(--fs-32) !important; }
.#{$pr}fs-34 { font-size: var(--fs-34) !important; }
.#{$pr}fs-36 { font-size: var(--fs-36) !important; }
.#{$pr}fs-40 { font-size: var(--fs-40) !important; }
.#{$pr}fs-44 { font-size: var(--fs-44) !important; }
.#{$pr}fs-45 { font-size: var(--fs-45) !important; }
.#{$pr}fs-48 { font-size: var(--fs-48) !important; }
.#{$pr}fs-50 { font-size: var(--fs-50) !important; }
.#{$pr}fs-56 { font-size: var(--fs-56) !important; }
.#{$pr}fs-64 { font-size: var(--fs-64) !important; }
.#{$pr}fs-72 { font-size: var(--fs-72) !important; }
.#{$pr}fs-78 { font-size: var(--fs-78) !important; }
.#{$pr}fs-80 { font-size: var(--fs-80) !important; }
.#{$pr}fs-90 { font-size: var(--fs-90) !important; }
.#{$pr}fs-100 { font-size: var(--fs-100) !important; }
