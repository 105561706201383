ngb-modal-window {
  &.modal-window-light {
    .modal-content {
      background-color: var(--bs-white);
      color: var(--bs-dark);
    }
  }

  @mixin fullscreen {
    .modal-dialog {
      margin: auto;
      height: 100%;
      max-width: unset;

      .modal-content {
        border-radius: unset;
        background: var(--bs-white);
        border: none;
        height: 100%;
        overflow: auto;

        > * {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.fullscreen-md {
    @include media-breakpoint-down(md) {
      @include fullscreen;
    }
  }

  &.fullscreen-sm {
    @include media-breakpoint-down(sm) {
      @include fullscreen;
    }
  }

  .modal-content {
    background-color: var(--bs-dark-2-);
    color: var(--bs-white);
    border-radius: 0.625rem;
    overflow: hidden;

    .btn-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 2rem;
      height: 2rem;
      padding: 0;
      margin: 0;
      box-shadow: none !important;
      z-index: 2000;
      opacity: 1 !important;

      &.btn-close-dark {
        background: transparent url('/assets/icons/close-bordered-black.svg') center/contain no-repeat;
      }

      &.btn-close-light {
        background: transparent url('/assets/icons/close-bordered-white-no-shadow.svg') center/contain no-repeat;
      }

      &.btn-close-light-with-shadow {
        background: transparent url('/assets/icons/close-bordered-white.svg') center/contain no-repeat;
      }
    }
  }
}

ngb-modal-window.play-detail-modal-window {
  @include media-breakpoint-up(sm) {
    .modal-lg, .modal-xl {
      max-width: 600px;
    }
  }

  @include media-breakpoint-up(lg) {
    .modal-lg, .modal-xl {
      max-width: 750px;
    }
  }

  @include media-breakpoint-down(sm) {
    .modal-content {
      background: var(--bs-dark-2-) !important;
    }
  }
}

ngb-modal-window {
  &.auth-login-modal-window, &.auth-reset-password-modal-window, &.newsletter-modal-window {
    @include media-breakpoint-up(md) {
      .modal-lg, .modal-xl {
        max-width: 750px;
      }
    }
  }
}

ngb-modal-window.auth-subscribe-modal-window {
  app-button-form {
    button {
      text-transform: uppercase;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .form-label {
    text-transform: uppercase;
  }

  @include media-breakpoint-up(lg) {
    .modal-lg, .modal-xl {
      max-width: 1200px;
      padding: 2rem;
    }

    .modal-content {
      min-height: 600px;
      justify-content: center;
    }
  }
}

ngb-modal-window.free-trial-modal-window {
  @include media-breakpoint-up(lg) {
    .modal-lg, .modal-xl {
      max-width: 1200px;
      padding: 2rem;
    }

    .modal-content {
      min-height: 600px;
      justify-content: center;
    }
  }
}

ngb-modal-window.live-modal-window {
  @include media-breakpoint-up(lg) {
    .modal-content {
      overflow: visible;
    }
  }
}
