:root {
  --app-form-active-color: var(--bs-app-red);
  --app-form-label-font-size: 0.9rem;
  --app-form-control-font-size: 1rem;

  .form__ondark {
    --app-form-label-color: var(--bs-white);
    --app-form-control-color: var(--bs-gray-500);
  }
}

input {
  &.autocomplete-breaker {
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
    border: 0;
    opacity: 0;
  }
}


