.swal2-container {
  .swal2-title {
    font-family: var(--app-ff-gilroy-b);
  }

  .swal2-html-container {
    font-size: 1rem;
    font-family: var(--app-ff-opensans-r);
  }
}
