@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";

swiper {
  &:hover {
    .swiper-button-prev, .swiper-button-next {
      &:not(.swiper-button-disabled) {
        opacity: 1;
      }
    }
  }

  &.transition-linear {
    .swiper-wrapper {
      transition-timing-function: linear;
    }
  }

  .swiper-max-height {
    max-height: 11.5rem;

    @include media-breakpoint-down(lg) {
      max-height: 14rem;
    }
  }

  &.swiper-navigation-edge {
    .swiper-button-prev, .swiper-button-next {
      color: var(--bs-white);
      border-radius: 0;
      width: 1.5rem;
      height: 100%;
      top: 0;
      transform: none;
      margin-top: 0;

      @include media-breakpoint-up(xl) {
        width: 3rem;
      }

      &::before {
        content: '';
        display: none;
      }

      &::after {
        font-size: 1rem;
        font-weight: bolder;
        z-index: 1;
      }
    }

    .swiper-button-prev {
      left: 0;
      transform: translateX(-100%);
      background: linear-gradient(to right, var(--bs-dark), transparent);
    }

    .swiper-button-next {
      right: 0;
      transform: translateX(100%);
      background: linear-gradient(to left, var(--bs-dark), transparent);
    }
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    color: var(--bs-white);
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      width: 3rem;
      height: 3rem;
      background: var(--bs-app-red);
      border-radius: 50%;
    }

    &::after {
      font-size: 1rem;
      font-weight: bolder;
      z-index: 1;
    }
  }

  .swiper-pagination {
    &.swiper-pagination-bullets {
      .swiper-pagination-bullet {
        background: var(--bs-white);
        width: 1rem;
        height: 1rem;
        margin: 0 0.5rem;

        @include media-breakpoint-down(sm) {
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }
  }
}
