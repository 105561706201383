@import "gilroy.scss";
@import "opensans.scss";
@import "teatrix-icons.scss";

[class^="teatrix-icon-"], [class*=" teatrix-icon-"] {
  &.icon-fw {
    width: 2rem;
    display: inline-block;
  }
}

.fw-lighter {
  font-weight: normal !important;
  font-family: var(--app-ff-gilroy-ul);
}

.fw-light {
  font-weight: normal !important;
  font-family: var(--app-ff-gilroy-l);
}

.fw-normal {
  font-weight: normal !important;
  font-family: var(--app-ff-gilroy-r);
}

.fw-bold,
strong,
th {
  font-weight: normal !important;
  font-family: var(--app-ff-gilroy-sb);
}

.fw-bolder {
  font-weight: normal !important;
  font-family: var(--app-ff-gilroy-b);
}

.fw-bolderest {
  font-weight: normal !important;
  font-family: var(--app-ff-gilroy-eb);
}

/* -------------------- */

.fw-default {
  font-weight: normal !important;
  font-family: var(--app-ff-gilroy-d);
}
