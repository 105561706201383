.nowrap {
  white-space: nowrap !important;
}

.text-marginless {
  p, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
  }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: normal !important;
}

a:not([href]) {
  cursor: pointer;
}

.actionable {
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}
