.grid {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: auto;
  grid-template-columns: 100%;

  &.grid-tb {
    grid-template-rows: auto max-content;

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  &.grid-tb-inverse {
    grid-template-rows: max-content auto;

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  &.grid-hbf {
    grid-template-rows: max-content auto max-content;
  }

  &.grid-page {
    grid-template-rows: max-content auto max-content;
    min-height: 100vh;
  }
}
