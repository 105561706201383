.form-field.one-line {
  &.magic-label {
    margin-top: #{$form-control-font-size};
  }

  > label {
    margin-bottom: 0;
    background-color: transparent;
  }

  > .form-icon {
    left: 0;
    top: #{$form-icon-top-calc-one-line};
  }

  > .form-control {
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }

  &:not(.has-label) {
    > .form-icon {
      top: #{$form-icon-top-calc-without-label};
    }
  }

  &.magic-label {
    > label {
      left: 0;
      padding: 0;
    }

    &.has-icon {
      &:not(.focus):not(.filled) {
        > label {
          left: calc(#{$form-control-padding-x-with-icon} - #{$form-control-padding-x});
        }
      }
    }

    &.focus, &.filled {
      > label {
        top: calc(0rem - #{$form-label-font-size});
        font-size: #{$form-label-font-size};
      }
    }
  }

  &.has-icon {
    > .form-control {
      padding-left: calc(#{$form-control-padding-x-with-icon} - #{$form-control-padding-x});
    }
  }
}
