@font-face {
  font-family: "Gilroy-UltraLight";
  src: url("/assets/fonts/gilroy/Gilroy-UltraLight.woff2") format('woff2'),
    url("/assets/fonts/gilroy/Gilroy-UltraLight.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "Gilroy-Light";
//   src: url("/assets/fonts/gilroy/Gilroy-Light.woff2") format('woff2'),
//     url("/assets/fonts/gilroy/Gilroy-Light.woff") format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: "Gilroy-Regular";
  src: url("/assets/fonts/gilroy/Gilroy-Regular.woff2") format('woff2'),
    url("/assets/fonts/gilroy/Gilroy-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "Gilroy-Medium";
//   src: url("/assets/fonts/gilroy/Gilroy-Medium.woff2") format('woff2'),
//     url("/assets/fonts/gilroy/Gilroy-Medium.woff") format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Gilroy-SemiBold";
//   src: url("/assets/fonts/gilroy/Gilroy-SemiBold.woff2") format('woff2'),
//     url("/assets/fonts/gilroy/Gilroy-SemiBold.woff") format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: "Gilroy-Bold";
  src: url("/assets/fonts/gilroy/Gilroy-Bold.woff2") format('woff2'),
    url("/assets/fonts/gilroy/Gilroy-Bold.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "Gilroy-ExtraBold";
//   src: url("/assets/fonts/gilroy/Gilroy-ExtraBold.woff2") format('woff2'),
//     url("/assets/fonts/gilroy/Gilroy-ExtraBold.woff") format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

:root {
  --app-ff-gilroy-ul: "Gilroy-UltraLight";
  --app-ff-gilroy-l: "Gilroy-UltraLight";
  --app-ff-gilroy-r: "Gilroy-Regular";
  --app-ff-gilroy-m: "Gilroy-Regular";
  --app-ff-gilroy-sb: "Gilroy-Bold";
  --app-ff-gilroy-b: "Gilroy-Bold";
  --app-ff-gilroy-eb: "Gilroy-Bold";
  /* -------------------- */
  --app-ff-gilroy-d: var(--app-ff-gilroy-r);
}

.ff-gilroy-ul { font-family: var(--app-ff-gilroy-ul) !important; font-weight: normal !important; }
.ff-gilroy-l { font-family: var(--app-ff-gilroy-l) !important; font-weight: normal !important; }
.ff-gilroy-r { font-family: var(--app-ff-gilroy-r) !important; font-weight: normal !important; }
.ff-gilroy-m { font-family: var(--app-ff-gilroy-m) !important; font-weight: normal !important; }
.ff-gilroy-sb { font-family: var(--app-ff-gilroy-sb) !important; font-weight: normal !important; }
.ff-gilroy-b { font-family: var(--app-ff-gilroy-b) !important; font-weight: normal !important; }
.ff-gilroy-eb { font-family: var(--app-ff-gilroy-eb) !important; font-weight: normal !important; }
/* -------------------- */
.ff-gilroy-d { font-family: var(--app-ff-gilroy-r) !important; font-weight: normal !important; }
